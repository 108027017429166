.step-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 23rem;
}

.step-item:not(:first-child)::before {
    content: '';
    background-color: #e2e8f0;
    position: absolute;
    width: 100%;
    height: 3px;
    right: 50%;
    top: 20%;

    transform: translateY(-50%);
}

.step {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    background-color: #374151;
    border-radius: 9999px;
    font-weight: 600;
    color: #fff;
}

.active .step {
    background-color: #0284c7;
}

.complete .step {
    background-color: #059669;
}

.complete p {
    color: #fff;
}

.complete:not(:first-child)::before,
.active:not(:first-child)::before {
    background-color: #059669;
}