@font-face {
    font-family: "Urbanist";
    src: url("../fonts/Urbanist-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: "Urbanist";
    src: url("../fonts/Urbanist-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("../fonts/Urbanist-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: "Urbanist";
    src: url("../fonts/Urbanist-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("../fonts/Urbanist-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}
