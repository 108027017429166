@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="range"] {
    @apply w-full h-0.2 bg-primary rounded-lg appearance-none;
}
@page {
    size: A4 Potrait;
    display: "block";
    margin: 10mm 5mm !important;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 30px;
    width: 20px;
    border-radius: 0.5rem;
    background-color: #171d39;
    cursor: pointer;
}

.list__disc ul li {
    list-style: disc;
    margin-left: 0.5rem;
    /* font-family: "Urbanist"; */
}

@media (min-width: 640px) {
    input[type="range"]::-webkit-slider-thumb {
        width: 33px;
        height: 40px;
    }
}

@media (min-width: 1024px) {
    input[type="range"]::-webkit-slider-thumb {
        height: 47px;
        width: 35px;
        border-radius: 1rem;
    }
}

.hollow-disc {
    list-style: circle;
}
